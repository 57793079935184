<template>
    <h2 v-if="english">Delivery report</h2>
    <h2 v-else>Leveringsrapport</h2>
    <div v-if="english">
        Messages will sometimes not reach the recipient. There are different reasons for this happening, eg. invalid or inactive mobile number.
        The delivery report will give you information on the delivery status for each message sent. The report will be sent to your server. Choose GET or POST method.
    </div>
    <div v-else>
        Av og til skjer det at SMS ikke kommer fram til mottaker. Det kan være ulike årsaker til dette, f.eks. ugyldig eller ikke aktivt mobilnummer.
        Våre kunder kan motta en leveringsrapport for hver sendte melding. Rapporten inneholder informasjon om meldingen ble sendt til mottaker eller ikke.
        For å ta i bruk leveringsrapport må det lages et script/program som kan motta data på egen server. Dette kan f.eks. være php, .Net eller Java. Velg GET eller POST.
    </div>

    <div class="pt-4">
        <span v-if="english">You can configure your URL on the page <strong>API -> Innstillinger</strong>:</span>
        <span v-else>URL til skriptet registreres på siden <strong>API -> Innstillinger</strong>:</span>
    </div>
    
    <div class="my-6 pb-2 border border-gray-300 shadow-lg inline-block">
        <img src="/img/api-help-dlr.png" alt="">
    </div>
    
    <div class="py-2 font-semibold">Parametere</div>
    <div class="overflow-x-auto">
        <table class="api-table">
            <thead><tr><th>Parameter</th><th>Type</th><th>{{ english ? 'Description' : 'Beskrivelse' }}</th></tr></thead>
            <tbody>
                <tr v-for="(p,i) in english ? params_eng : params" :key="i">
                    <td v-html="p.param"></td>
                    <td v-html="p.type"></td>
                    <td v-html="p.desc"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="pt-6 pb-2">
        <span v-if="english">Request example (get):</span>
        <span v-else>Eksempel på et kall (get) scriptet må kunne motta:</span>
    </div>
    <div class="api-example">
        https://dittdomene.no/sms_dlr.php?number=91654321&status=true&id=42824112&ref=ABC123&errorCode=&errorDesc=
    </div>

    <ApiDocPushInterval :english="english" />
</template>

<script>
import ApiDocPushInterval from '@/components/APIdocPushInterval.vue';

export default {
    components: {
        ApiDocPushInterval,
    },
    props: {
        english: Boolean,
    },
    data() {
        return {
            params: [
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Mottakers mobilnummer'
                },
                {
                    param: 'status',
                    type: 'Boolean',
                    desc: 'true: melding sendt ok<br>false: meldingen kunne ikke sendes'
                },
                {
                    param: 'id',
                    type: 'Integer',
                    desc: 'Meldings-ID'
                },
                {
                    param: 'ref',
                    type: 'String',
                    desc: 'Din egen referanse'
                },
                {
                    param: 'errorCode',
                    type: 'String',
                    desc: 'Feilkode'
                },
                {
                    param: 'errorDesc',
                    type: 'String',
                    desc: 'Beskrivelse av feil'
                },                                                                                
            ],
            params_eng: [
                {
                    param: 'number',
                    type: 'Long',
                    desc: 'Recipient mobile number'
                },
                {
                    param: 'status',
                    type: 'Boolean',
                    desc: 'true: message sent ok<br>false: message could not be sent'
                },
                {
                    param: 'id',
                    type: 'Integer',
                    desc: 'Message ID'
                },
                {
                    param: 'ref',
                    type: 'String',
                    desc: 'Your reference'
                },
                {
                    param: 'errorCode',
                    type: 'String',
                    desc: 'Error code'
                },
                {
                    param: 'errorDesc',
                    type: 'String',
                    desc: 'Error description'
                },                                                                                
            ]            
        }
    }
}
</script>