<template>

    <h2>Send SMS - HTTP GET/POST</h2>

    <div class="pt-2 pb-6">
        <span v-if="english">Send SMS example (get):</span>
        <span v-else>Eksempel på utsendelse av SMS (get):</span>
        <div class="api-example">
            https://sveve.no/SMS/SendMessage?user=xxx&passwd=xxx&to=xxx&msg=Meldingstekst&f=json
        </div>
    </div>

    <APIdocTable :tableData="english ? tableData_eng : tableData" />    

    <div class="pt-6 pb-4">
        <div v-if="english">
            Max GET request size: 6900 characters
        </div>
        <div v-else>
            Vi støtter både HTTP GET og POST for å sende SMS. 
            Maksimal størrelse på en GET request er 6900 tegn, som tilsvarer ca 700 enkeltmottakere.
            Vi anbefaler uansett å bruke POST dersom man skal sende til mange enkeltmottakere i samme request.
        </div>
    </div>

    <div class="mt-4">
        <span v-if="english">3 alternatives for POST:</span>
        <span v-else>Ved bruk av POST finnes det 3 alternativer:</span>
    </div>    
    <div class="text-base">        
        <div class="mt-3">
            <div class="font-semibold">1. Body JSON</div>
            <div v-if="english">Three options. The examples shows only some of the parameters, however all parameters are supported.</div>
            <div v-else>Tre varianter støttes. Eksemplene viser kun noen av parameterne. Alle parameterne i tabellen over støttes.</div>            
            <div class="mt-1 text-sm" v-for="(ex, i) in english ? jsonRequestExample_eng : jsonRequestExample" :key="i">
                <div class="font-semibold">{{ ex.heading }}:</div>
                <div class="overflow-x-auto text-gray-700"><pre>{{ ex.json }}</pre></div>
            </div>     
        </div>

        <div class="mt-3">
            <div class="font-semibold">2. Body parameter</div>
            <div v-if="english">Form-data parameters</div>
            <div v-else>Parametere sendes som form-data.</div>
        </div>

        <div class="mt-3"> 
            <div class="font-semibold">3. URL parameter</div>
            <div v-if="english">Same as GET but with POST as method. Parameters in URL, not in request body.</div>
            <div v-else>Samme som GET, men POST som metode. Parameterne sendes i URL og ikke i request body.</div>
        </div>
        
        <div class="mt-5"> 
            <div v-if="english">Set ContentType=UTF-8 in all POST requests.</div>
            <div v-else>NB! sett ContentType=UTF-8 i POST-kallene.</div>
        </div>

        <div class="mt-2">
            <span v-if="english">Download <a href="../w1/SveveAPI-postman.json" target="_blank">Postman templates</a> for all alternatives.</span>
            <span v-else>Du kan laste ned <a href="../w1/SveveAPI-postman.json" target="_blank">Postman-maler</a> for alle alternativene.</span>
        </div>
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->
    
    <div class="font-semibold mt-6">Retur ved SMS-kall</div>
    <div v-if="english">
        Each request will return some information, both for GET and POST. We recommend using JSON as return format (f=json).
    </div>
    <div v-else>
        For hvert kall som gjøres blir det returnert informasjon om utsendelsen. Dette gjelder både for GET og POST. Vi anbefaler å benytte JSON som returformat (f=json) i kallene.
    </div>
    <div class="py-4">
        <div class="pt-2 text-sm">                        
            <div class="pb-2" v-for="(ex, i) in english ? jsonResponseExample_eng : jsonResponseExample" :key="i">
                <div class="font-semibold">{{ ex.heading }}:</div>
                <div class="overflow-x-auto text-gray-700"><pre>{{ ex.json }}</pre></div>
            </div>                    
        </div>
        
        <div class="pt-2 pb-1 font-semibold">Response-objekt</div>
        <div class="overflow-x-auto">
            <table class="api-table">
                <thead>
                    <tr><th>Key</th><th>Type</th><th>{{ english ? 'Description' : 'Beskrivelse' }}</th></tr>
                </thead>
                <tbody>
                    <tr v-for="(response,r) in english ? responseDesc_eng : responseDesc" :key="r">
                        <td v-html="response.key"></td>
                        <td v-html="response.type"></td>
                        <td v-html="response.desc"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->

    <div v-if="english">
        <div class="font-semibold mt-6">Charset and characters per sms unit</div>
        <div>
            The following characters are part of the GSM 7-bit specification. For these characters each sms unit contains 160 characters.
            For longer messages each unit contains 153 characters because 7 characters are used for header in each part. The header is essential for correct unit assembly order on the receivers phone.
            Sveve supports messages up to 1071 characters.
            <div class="text-base mt-3 mb-4">
                <div class="font-semibold">GSM-7</div>
                <span v-for="(c, i) in gsm7chars" :key="i" class="mr-1 inline-block" v-html="c"></span>
            </div>
            If other characters exists in the message text, each unit will contain 70 characters. Sveve supports all Unicode-characters with UTF-8 encoding, including emojis.
        </div>        
    </div>

    <div v-else>
        <div class="font-semibold mt-6">Tegnsett og antall tegn pr. melding</div>
        <div>
            Følgende tegn hører til GSM 7-bit spesifikasjonen. Ved bruk av disse tegnene vil en sms-enhet bestå av 160 tegn. 
            Ved lengre meldinger vil hver enhet bestå av 153 tegn, da 7 tegn brukes som header i hver delmelding slik at de kan settes sammen i riktig rekkefølge på telefonen.
            Sveve støtter meldinger på inntil 1071 tegn.
            <div class="text-base mt-3 mb-4">
                <div class="font-semibold">GSM-7</div>
                <span v-for="(c, i) in gsm7chars" :key="i" class="mr-1 inline-block" v-html="c"></span>
            </div>
            Ved bruk av tegn utenom GSM-7 vil en sms-enhet bestå av 70 tegn. Sveve støtter alle Unicode-tegn med UTF-8 encoding, inkludert emoji og samiske bokstaver.
        </div>
    </div>

    <!-------------------------------------------------------------------------------------------------------------------->
    
    <div v-if="english">
        <div class="font-semibold mt-6">Concurrent request limitations</div>
        <div class="text-base">
            To ensure a reliable and fast service, there is a limit on number of concurrent requests from each account.
            <div class="mt-2">
                The limit is <span class="font-semibold">5</span> concurrent API-requests
            </div>
            <div class="mt-3">
                <div class="font-semibold">Tips on how to setup the integration:</div>
                <ul>
                    <li>Read the response on the previous request before sending the next</li>
                    <li>If the limit is reached the server will respond with http status 429 (Too Many Requests)</li>
                    <li>Every request only takes a few milliseconds. You can send as many messages as you like, but you need to limit the number of concurrent requests, or handle the 429 status.</li>
                </ul>
            </div>
            <div class="mt-4">
                Need more concurrent requests?<br>            
                If you need to increase the concurrent request limit, send us on e-mail: post@sveve.no. 
                This could be if you send requests from multiple servers or if you have other reasons to make multiple concurrent requests.          
            </div>
        </div>        
    </div>
    <div v-else>
        <div class="font-semibold mt-6">Begrensninger i samtidige API-forespørsler</div>
        <div class="text-base">
            For å sikre en stabil og rask tjeneste er det en begrensning på antall samtidige API-kall per brukerkonto. Dette påvirker ikke hvor mange SMS som kan sendes.
            <div class="mt-2">
                Det er en grense på <span class="font-semibold">5</span> samtidige forespørsler mot API-et
            </div>
            <div class="mt-3">
                <div class="font-semibold">Tips til robust integrasjon:</div>
                <ul>
                    <li>Les svaret som kommer fra APIet på én forespørsel før en ny sendes</li>
                    <li>Dersom det gjøres for mange samtidige kall vil serveren svare med http status 429 (Too Many Requests)</li>
                    <li>Hvert kall tar kun noen millisekunder, så dette påvirker ikke hvor mange SMS du kan sende. Det begrenser bare antall samtidige, parallelle kall mot APIet</li>
                </ul>
            </div>
            <div class="mt-4">
                Behov for flere samtidige kall?<br>            
                Dersom du har behov for flere samtidige kall kan du kontakte oss på post@sveve.no. Dette kan være fordi du sender SMS fra flere ulike servere, eller at du av andre grunner har behov for flere samtidige kall.            
            </div>
        </div>
    </div>

</template>

<script>
import APIdocTable from '@/components/APIdocTable.vue';

export default {
    components: {
        APIdocTable
    },
    props: {
        english: Boolean,
    },
    data() {
        return {
            tableData: {
                columns: [ 'Parameter', 'Type', 'Maks lengde', 'Obligatorisk', 'Default', 'Beskrivelse' ],
                highlightColumn: 0,
                rows: [
                    ['user', 'String', '', true, '', 'Brukernavn'],
                    ['passwd', 'String', '', true, '', 'API passord. Dette finner du på siden API -> API-nøkkel'],
                    ['to', 'String', '', true, '', 'Mottakers mobilnummer (kommaseparert ved flere), og/eller navn på eksisterende mottakergruppe'],
                    ['msg', 'String', '1071', true, '', 'Selve meldingsteksten. Det forutsettes at teksten er UTF-8 encoded.'],
                    ['from', 'String', '11', false, '27333', 'Avsender som vises på mottakers telefon (f.eks. firmanavn eller ditt eget mobilnummer). ÆØÅ kan ikke benyttes. Minimum 3 tegn anbefales for korrekt visning på alle mobiltelefoner.'],
                    ['reply', 'Boolean', '', false, 'false', 'Meldingen kan besvares. Ved aktivering (true) blir avsender et nummer med 14 siffer.'],
                    ['reply_id', 'Integer', '', false, '', 'ID på tidligere sendt melding (sendt med reply=true). Fører til at meldingene kommer i samme meldingstråd på mottakers telefon ved bruk av reply=true.'],
                    ['date_time', 'String', '12', false, '', 'Dato/klokkeslett for planlagt utsendelse. Format: yyyyMMddttmm. Eksempel: 202310061315 (6. okt. 2023 kl. 13:15). NB! maks 5000 tegn som "to"-verdi støttes ved planlagt utsendelse'],
                    ['reoccurrence', 'String', '', false, '', 'Gjentakelse av utsendelse av samme melding (sendt med date_time). Format: verdi|enhet<br>Verdier:<br>11=time, 5=dag, 99=uke, 2=måned<br>Eks 1: 6|11 (hver 6. time)<br>Eks 2: 14|5 (hver 14. dag)'],
                    ['reoccurrence_ends', 'String', '', false, 'never', 'Gyldige verdier: never, after, on<br>never=gjentakelse stopper aldri<br>after=gjentakelse stopper etter et antall ganger<br>on=gjentakelse stopper etter en dato'],
                    ['ends_after', 'Integer', '', false, '', 'Brukes dersom reoccurrence_ends=after. Gjentakelse stopper etter angitt antall ganger.'],
                    ['ends_on', 'String', '', false, '', 'Brukes dersom reoccurrence_ends=on. Gjentakelse stopper etter angitt dato (format: dd.MM.yyyy), eks: 31.12.2024'],
                    ['ref', 'String', '36', false, '', 'Din egen referanse. Vil bli sendt tilbake i leveringsrapport.'],
                    ['f', 'String', '', false, 'xml', 'Returformat, gyldige verdier: json, xml'],
                    ['test', 'Boolean', '', false, 'false', 'Sett denne til \'true\' for å gjøre test-kall som ikke medfører utsendelse av SMS'],
                ]
            },
            tableData_eng: {
                columns: [ 'Parameter', 'Type', 'Max length', 'Mandatory', 'Default', 'Description' ],
                highlightColumn: 0,
                rows: [
                    ['user', 'String', '', true, '', 'Username'],
                    ['passwd', 'String', '', true, '', 'API password. You find this on the page API -> API-nøkkel'],
                    ['to', 'String', '', true, '', 'Receivers mobile number (comma separated if multiple), and/or existing group name'],
                    ['msg', 'String', '1071', true, '', 'Message text, UTF-8 encoded.'],
                    ['from', 'String', '11', false, '27333', 'Sender showing on receivers phone (eg. company name or your mobile number). No special characters. Minimum 3 characters is recommended to support iPhone.'],
                    ['reply', 'Boolean', '', false, 'false', 'Set reply option. If true sender will be a 14-digit number (autogenerated).'],
                    ['reply_id', 'Integer', '', false, '', 'ID on previous sent message (with reply=true). This will keep the message in the same message thread on receivers phone.'],
                    ['date_time', 'String', '12', false, '', 'Date/time for scheduled message. Format: yyyyMMddttmm. Eg: 202310061315 (6. okt. 2023 @ 13:15). NB! max 5000 characters in "to"-parameter for scheduled messages.'],
                    ['reoccurrence', 'String', '', false, '', 'Send message multiple times (require original message sent with date_time). Format: value|unit<br>Values:<br>11=hour, 5=day, 99=week, 2=month<br>Eg. 1: 6|11 (every 6th hour)<br>Eg. 2: 14|5 (every 14th day)'],
                    ['reoccurrence_ends', 'String', '', false, 'never', 'Accepted values: never, after, on<br>never=reoccurrence never stops<br>after=reoccurrence stops after a set count<br>on=reoccurrence stops after a date'],
                    ['ends_after', 'Integer', '', false, '', 'Required if reoccurrence_ends=after. Reoccurrence stops after the provided count value.'],
                    ['ends_on', 'String', '', false, '', 'Required if reoccurrence_ends=on. Reoccurrence stops after provided date (format: dd.MM.yyyy), ex: 31.12.2024'],
                    ['ref', 'String', '36', false, '', 'Your own reference. This will be included in delivery reports.'],
                    ['f', 'String', '', false, 'xml', 'Return format, valid options: json, xml'],
                    ['test', 'Boolean', '', false, 'false', 'Set this to \'true\' for testing without sending SMS'],
                ]
            },            
            jsonRequestExample: [
                {
                    heading: 'Variant 1 (én meldingstekst, en eller flere mottakere)',
                    json: {
                        "user": "brukernavn",
                        "passwd": "api-passord",
                        "to": "mobilnummer1, mobilnummer2, ..",                        
                        "msg": "Meldingstekst",
                        "from": "MinBedrift",
                        "f": "json",
                        "test": false
                    }
                },
                {
                    heading: 'Variant 2 (én meldingstekst, en eller flere mottakere)',
                    json: {
                        "request": {
                            "user": "brukernavn",
                            "passwd": "api-passord",
                            "to": "mobilnummer1, mobilnummer2, ..", 
                            "msg": "Meldingstekst",
                            "from": "MinBedrift",
                            "f": "json",
                            "test": false
                        }
                    }
                },
                {
                    heading: 'Variant 3 (ulike meldingstekster i samme kall)',
                    json: {
                        "user": "brukernavn",
                        "passwd": "api-passord",
                        "f": "json",
                        "test": false,
                        "messages": [
                            {
                                "to": "mobilnummer1",
                                "msg": "Meldingstekst 1",
                                "from": "MinBedrift"
                            },
                            {
                                "to": "mobilnummer2",
                                "msg": "Meldingstekst 2",
                                "from": "MinBedrift"
                            }
                        ]                        
                    }
                }                            
            ],
            jsonRequestExample_eng: [
                {
                    heading: 'Option 1 (single message to one or more recipients)',
                    json: {
                        "user": "username",
                        "passwd": "api-password",
                        "to": "number1, number2, ..",
                        "msg": "Message text",
                        "from": "MyCompany",
                        "f": "json",
                        "test": false
                    }
                },
                {
                    heading: 'Option 2 (single message to one or more recipients)',
                    json: {
                        "request": {
                            "user": "username",
                            "passwd": "api-password",
                            "to": "number1, number2, ..",
                            "msg": "Message text",
                            "from": "MyCompany",
                            "f": "json",
                            "test": false
                        }
                    }
                },
                {
                    heading: 'Option 3 (multipple messages)',
                    json: {
                        "user": "username",
                        "passwd": "api-password",
                        "f": "json",
                        "test": false,
                        "messages": [
                            {
                                "to": "number1",
                                "msg": "Message text 1",
                                "from": "MyCompany"
                            },
                            {
                                "to": "number2",
                                "msg": "Message text 2",
                                "from": "MyCompany"
                            }
                        ]
                    }                    
                }                             
            ],            
            jsonResponseExample: [
                {
                    heading: 'Vellykket utsendelse (2 meldinger sendt)',
                    json: {
                        "response": {
                            "msgOkCount": 2,
                            "stdSMSCount": 4,        
                            "ids": [42824111, 42824112]        
                        }
                    } 
                },
                {
                    heading: 'Utsendelsen feilet (ingen meldinger sendt)',
                    json: {
                        "response": {
                            "msgOkCount": 0,
                            "stdSMSCount": 0,        
                            "fatalError": "Feil brukernavn/passord",
                            "ids": []
                        }
                    }
                },
                {
                    heading: 'Utsendelsen feilet delvis (1 melding ble sendt og 2 ble avvist)',
                    json: {
                        "response": {
                            "msgOkCount": 1,
                            "stdSMSCount": 1,        
                            "errors": [        
                                {
                                "number": "1792873691",
                                "message": "Brukeren har ikke tilgang til å sende meldinger til dette landet"
                                },
                                {
                                "number": "63987654",
                                "message": "Telefonnummeret er ikke et mobilnummer"
                                }
                            ],        
                            "ids": [42824387]        
                        }
                    }
                }
            ],
            jsonResponseExample_eng: [
                {
                    heading: 'Successful (2 messages sent)',
                    json: {
                        "response": {
                            "msgOkCount": 2,
                            "stdSMSCount": 4,        
                            "ids": [42824111, 42824112]        
                        }
                    } 
                },
                {
                    heading: 'Failed (no messages sent)',
                    json: {
                        "response": {
                            "msgOkCount": 0,
                            "stdSMSCount": 0,        
                            "fatalError": "Feil brukernavn/passord",
                            "ids": []
                        }
                    }
                },
                {
                    heading: 'Partial success (1 message sent and 2 rejected)',
                    json: {
                        "response": {
                            "msgOkCount": 1,
                            "stdSMSCount": 1,        
                            "errors": [        
                                {
                                "number": "1792873691",
                                "message": "Brukeren har ikke tilgang til å sende meldinger til dette landet"
                                },
                                {
                                "number": "63987654",
                                "message": "Telefonnummeret er ikke et mobilnummer"
                                }
                            ],        
                            "ids": [42824387]        
                        }
                    }
                }
            ],
            responseDesc: [
                {
                    key: 'msgOkCount',
                    type: 'Integer',
                    desc: 'Antall meldinger som ble sendt'
                },
                {
                    key: 'stdSMSCount',
                    type: 'Integer',
                    desc: 'Antall SMS-enheter totalt for utsendelsen. En SMS enhet består av inntil 160 tegn.'
                },
                {
                    key: 'fatalError',
                    type: 'String',
                    desc: 'Beskrivelse av feil dersom hele utsendelsen feilet'
                },
                {
                    key: 'errors',
                    type: 'Array',
                    desc: 'Beskrivelse av feil dersom utsendelse til enkeltnummer feilet'
                },
                {
                    key: 'ids',
                    type: 'Array',
                    desc: 'ID\'er på enkeltmeldingene som ble sendt. Rekkefølge samsvarer med rekkefølgen av mobilnummer i utsendelsen'
                },                                                
            ],
            responseDesc_eng: [
                {
                    key: 'msgOkCount',
                    type: 'Integer',
                    desc: 'Number of messages sent'
                },
                {
                    key: 'stdSMSCount',
                    type: 'Integer',
                    desc: 'Total number of SMS units for this request. One SMS unit is limited to 160 characters.'
                },
                {
                    key: 'fatalError',
                    type: 'String',
                    desc: 'Error description if request failed'
                },
                {
                    key: 'errors',
                    type: 'Array',
                    desc: 'Error description if request partial failed'
                },
                {
                    key: 'ids',
                    type: 'Array',
                    desc: 'IDs for sent messages. The order matches the mobile number order in request.'
                },                                                
            ],
            gsm7chars: ['@','£','$','¥','è','é','ù','ì','ò','Ç','Ø','ø','Å','å','Δ','_','Φ','Γ','Λ','Ω','Π','Ψ','Σ','Θ','Ξ','^','{','}','\\','[','~',']','|','€','Æ','æ','ß','É','!','"','#','¤','%','&','\'','(',')','*','+',',','-','.','/','0','1','2','3','4','5','6','7','8','9',':',';','<','=','>','?','¡','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','Ä','Ö','Ñ','Ü','§','¿','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','ä','ö','ñ','ü','à'],
        }
    }
}
</script>

<style scoped>

ul {
    list-style-type: circle;
    list-style-position: outside;    
    margin-left: 20px;    
}
ul li {
    margin-top: 5px;
}

</style>