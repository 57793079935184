import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Contact from "@/pages/Contact.vue";
import Services from "@/pages/Services.vue";
import ServiceLandingPage from "@/pages/ServiceLandingPage.vue";
import DynamicLandingPage from "@/pages/DynamicLandingPage.vue";
import CMS from "@/pages/CMS.vue";
import Order from "@/pages/Order.vue";
import OrderProduct from "@/pages/OrderProduct.vue";
import OrderInfo from "@/pages/OrderInfo.vue";
import OrderFinish from "@/pages/OrderFinish.vue";
import Help from "@/pages/Help.vue";
import Login from "@/pages/Login.vue";
import Cookies from "@/pages/Cookies.vue";
import ApiDoc from "@/pages/ApiDoc.vue";
import ApiSend from "@/pages/ApiSend.vue";
import ApiDLR from "@/pages/ApiDLR.vue";
import ApiGroups from "@/pages/ApiGroups.vue";
import ApiMO from "@/pages/ApiMO.vue";
import ApiMisc from "@/pages/ApiMisc.vue";
import PrefixPortal from "@/pages/PrefixPortal.vue";
import NotFound from "@/pages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "forside",
    component: Home,
    meta: { 
      title: 'SMS-tjenester for bedrifter og organisasjoner',
      description: 'Sveve tilbyr alle typer SMS-tjenester til bedrifter og organisasjoner. Norsk kvalitetsleverandør siden 2006. Send SMS fra nett, SMS-API og kodeord'
    }
  },
  {
    path: "/tjenester",
    name: "tjenester",
    component: Services,
    meta: { 
      title: 'Sveve - Tjenester og priser',
      description: 'SMS-tjenester for bedrifter og organisasjoner. Oversikt over alle tjenester og priser på SMS-tjeneste, SMS-API, kodeord, dedikert nummer og e-post til SMS',
    }
  },
  {
    path: "/sms-tjeneste",
    name: "sms-tjeneste",
    component: ServiceLandingPage,
    props: {
      serviceName: 'BAS',
      isOption: false,
    }
  },  
  {
    path: "/sms-api",
    name: "sms-api",
    component: ServiceLandingPage,
    props: {
      serviceName: 'API',
      isOption: true
    } 
  },  
  {
    path: "/kodeord",
    name: "kodeord",
    component: ServiceLandingPage,
    props: {
      serviceName: 'KOD',
      isOption: true
    }  
  },  
  {
    path: "/dedikert-nummer",
    name: "dedikert-nummer",
    component: ServiceLandingPage,
    props: {
      serviceName: 'DED',
      isOption: true
    } 
  },  
  {
    path: "/epost-til-sms",
    name: "epost-til-sms",
    component: ServiceLandingPage,
    props: {
      serviceName: 'EPS',
      isOption: true
    } 
  },  
  {
    path: "/kontakt",
    name: "kontakt",
    component: Contact,
    meta: { 
      title: 'Sveve - Kontakt oss',
      description: 'Kontakt oss'
    }
  },
  {
    path: "/om",
    name: "About",
    component: About,
    meta: { 
      title: 'Om Sveve AS - SMS-tjenester for bedrifter og organisasjoner',
      description: 'Sveve AS er en kvalitetsleverandør som tilbyr SMS-tjenester og mobil kommunikasjon til norske bedrifter og organisasjoner'    
    }
  },
  {
    path: "/hjelp",
    name: "Help",
    component: Help,
    meta: { 
      title: 'Sveve - Hjelp',
      description: 'Hjelp/support'    
    }
  },
  {
    path: "/apidok",  
    redirect: "/apidok/send",  
    name: "ApiDoc",
    component: ApiDoc,
    meta: { 
      title: 'Teknisk API-dokumentasjon for SMS-tjenesten fra Sveve',
      description: 'Komplett teknisk API-dokumentasjon for SMS-tjenester fra Sveve. Lag en integrasjon mot din applikasjon og send- og motta SMS'
    },
    children: [
      {
        path: 'send',
        name: 'ApiSend',
        component: ApiSend,
        props: true,
      },
      {
        path: 'lev',
        name: 'ApiDLR',
        component: ApiDLR,       
        props: true,
      },
      {
        path: 'grupper',
        name: 'ApiGroups',
        component: ApiGroups,     
        props: true,
      },
      {
        path: 'motta',
        name: 'ApiMO',
        component: ApiMO,      
        props: true,
      },      
      {
        path: 'div',
        name: 'ApiMisc',
        component: ApiMisc,
        props: true,
        meta: {
          gtm: 'ApiMisc'
        }        
      }        
    ]    
  },   
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
    meta: { 
      title: 'Informasjonskapsler/cookies',
      description: 'Sveve respekterer personvernet til våre brukerne - slik brukes informasjonskapsler/cookies på nettsidene'
    }
  },  
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { 
      title: 'Logg inn på din konto hos Sveve',
      description: 'Logg inn på på din konto hos Sveve for å sende- og motta SMS eller for å administre dine mottakergrupper ',
      hideNavbar: true,
      hideFooter: true
    }
  },  
  {
    path: "/bestill",
    name: "bestill",
    redirect: "/bestill/1",
    history: false,
    component: Order,
    meta: { 
      title: 'Bestill en gratis prøvekonto på SMS-tjenester',
      description: 'Gratis og uforpliktende. Bestill en prøvekonto på SMS-tjeneste, SMS-API, kodeord eller e-post til SMS',
      hideNavbar: true,
      hideFooter: true,
    },
    children: [
      {
        path: '1',
        name: 'OrderProduct',
        component: OrderProduct,
      },
      {
        path: '2',
        name: 'OrderInfo',
        component: OrderInfo,
      },
      {
        path: '3',
        name: 'OrderFinish',
        component: OrderFinish,      
      } 
    ]
  },
  {
    path: "/artikler/:catchAll(.*)*",
    name: "artikler", 
    component: CMS,
    meta: { 
      title: 'Oversikt over artikler og nyheter om SMS-tjenester fra Sveve',
      description: 'En oversikt over nyttige artikler, smarte tips og råd for å komme i gang med effektiv mobil kommunikasjon hos Sveve'    
    }
  },
  {
    path: "/sider/:catchAll(.*)*",
    name: "sider", 
    component: DynamicLandingPage,
  },  
  {
    path: "/grupper/:shortnumber/:prefix",
    component: PrefixPortal,
    meta: { 
      title: 'Gruppe påmelding',
      description: '',
      hideNavbar: true,
      hideFooter: true,      
    }    
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound
  }
];


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior () {
    return { top: 0 }
  },
  routes: routes
});

export default router